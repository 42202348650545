exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-open-positions-strapi-jobs-url-js": () => import("./../../../src/pages/careers/open-positions/{StrapiJobs.url}.js" /* webpackChunkName: "component---src-pages-careers-open-positions-strapi-jobs-url-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-insights-strapi-insights-url-js": () => import("./../../../src/pages/insights/{StrapiInsights.url}.js" /* webpackChunkName: "component---src-pages-insights-strapi-insights-url-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-static-ar-strapi-static-pages-url-js": () => import("./../../../src/pages/static/ar/{StrapiStaticPages.url}.js" /* webpackChunkName: "component---src-pages-static-ar-strapi-static-pages-url-js" */),
  "component---src-pages-static-de-strapi-static-pages-url-js": () => import("./../../../src/pages/static/de/{StrapiStaticPages.url}.js" /* webpackChunkName: "component---src-pages-static-de-strapi-static-pages-url-js" */),
  "component---src-pages-static-strapi-static-pages-url-js": () => import("./../../../src/pages/static/{StrapiStaticPages.url}.js" /* webpackChunkName: "component---src-pages-static-strapi-static-pages-url-js" */),
  "component---src-pages-static-tr-strapi-static-pages-url-js": () => import("./../../../src/pages/static/tr/{StrapiStaticPages.url}.js" /* webpackChunkName: "component---src-pages-static-tr-strapi-static-pages-url-js" */),
  "component---src-pages-tr-careers-open-positions-strapi-jobs-url-js": () => import("./../../../src/pages/tr/careers/open-positions/{StrapiJobs.url}.js" /* webpackChunkName: "component---src-pages-tr-careers-open-positions-strapi-jobs-url-js" */),
  "component---src-pages-tr-hakkimizda-js": () => import("./../../../src/pages/tr/hakkimizda.js" /* webpackChunkName: "component---src-pages-tr-hakkimizda-js" */),
  "component---src-pages-tr-iletisim-js": () => import("./../../../src/pages/tr/iletisim.js" /* webpackChunkName: "component---src-pages-tr-iletisim-js" */),
  "component---src-pages-tr-index-js": () => import("./../../../src/pages/tr/index.js" /* webpackChunkName: "component---src-pages-tr-index-js" */),
  "component---src-pages-tr-insights-js": () => import("./../../../src/pages/tr/insights.js" /* webpackChunkName: "component---src-pages-tr-insights-js" */),
  "component---src-pages-tr-insights-strapi-insights-url-js": () => import("./../../../src/pages/tr/insights/{StrapiInsights.url}.js" /* webpackChunkName: "component---src-pages-tr-insights-strapi-insights-url-js" */),
  "component---src-pages-tr-products-js": () => import("./../../../src/pages/tr/products.js" /* webpackChunkName: "component---src-pages-tr-products-js" */),
  "component---src-pages-tr-projects-js": () => import("./../../../src/pages/tr/projects.js" /* webpackChunkName: "component---src-pages-tr-projects-js" */),
  "component---src-pages-tr-works-js": () => import("./../../../src/pages/tr/works.js" /* webpackChunkName: "component---src-pages-tr-works-js" */),
  "component---src-pages-tr-works-strapi-works-url-js": () => import("./../../../src/pages/tr/works/{StrapiWorks.url}.js" /* webpackChunkName: "component---src-pages-tr-works-strapi-works-url-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-pages-works-strapi-works-url-js": () => import("./../../../src/pages/works/{StrapiWorks.url}.js" /* webpackChunkName: "component---src-pages-works-strapi-works-url-js" */)
}

